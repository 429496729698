import {showResult, validateForm} from './js/script.js';
import './_snowpack/pkg/bootstrap/dist/css/bootstrap.css';

document.getElementById("get-result").addEventListener('click', (e) => {
    showResult(
        validateForm()
    );
    e.preventDefault();
});

document.getElementById("battery-calc").addEventListener('submit', (e) => {
    e.target.reset();
    document.getElementById("refDiy").classList.add('d-none');
    e.preventDefault();
});

//Calculation type selection event
document.getElementById("calc-type").addEventListener('change', (e) => {
    const optValue = e.target.value;
    document.getElementById("resultblock").classList.add('d-none');
    /* if(optValue > 0)
     {
         document.getElementById("actions").classList.remove('d-none');
     } else {
         document.getElementById("actions").classList.add('d-none');
     } */
    if (optValue == 2) {
        document.getElementById("refDiy").classList.remove('d-none');
    } else {
        document.getElementById("refDiy").classList.add('d-none');
    }

    if (optValue == 3) {
        document.getElementById("refFeedback").classList.remove('d-none');
        document.getElementById("simple-calc").classList.add('d-none');
    } else {
        document.getElementById("refFeedback").classList.add('d-none');
        document.getElementById("simple-calc").classList.remove('d-none');
    }
});