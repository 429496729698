//Form Submit Event
const element = (id) => document.getElementById(id);

//Calculation For Battery Capcity
const calculateBatteryCapacity = () => {
    const load = document.getElementById('appliance-load').value;
    const voltage = document.getElementById('battery-voltage').value;
    const duration = document.getElementById('battery-duration').value;

    try {
        return {
           type: 'batterycapacity',
           capacity: (duration * load) / voltage
        }
    } catch (error) {
        return error.message;
    }
}

//Calculation for Battery Pack Configuration
const getBatteryPackConfiguration = () => {
    const batteryCapacity = calculateBatteryCapacity();
    const batteryVoltage = document.getElementById('battery-voltage').value;
    const voltagePerCell = document.getElementById('single-cell-volt').value;
    const capacityPerCell = document.getElementById('capacity-per-cell').value;
    const capacityUnit = document.getElementById('capacity-unit').value;
    const unitValues = {
        mAh: 0.001,
        Ah: 1
    };

    try {
        const cellsInSeries = batteryVoltage / voltagePerCell;
        const cellsInParallel = batteryCapacity.capacity / (capacityPerCell * unitValues[capacityUnit]);
        return {
            type: 'batterypack',
            cellSeries: cellsInSeries,
            cellParallel: cellsInParallel
        }
    } catch (error) {
        console.log(error)
        return error.message;
    }
}

// Form validation
const validateForm = () => {
    if (element('calc-type').value == '1') {
        if (
            element('battery-duration').value.length > 0 && !isNaN(element('battery-duration').value) &&
            parseFloat(element('battery-duration').value) > 0 &&
            element('battery-voltage').value.length > 0 && !isNaN(element('battery-voltage').value) &&
            parseFloat(element('battery-voltage').value) > 0 &&
            element('appliance-load').value.length > 0 && !isNaN(element('appliance-load').value) &&
            parseFloat(element('appliance-load').value) > 0
        ) {
            return '';
        }

    }
    if (element('calc-type').value == '2') {
        if (
            element('battery-duration').value.length > 0 && !isNaN(element('battery-duration').value) &&
            parseFloat(element('battery-duration').value) > 0 &&
            element('battery-voltage').value.length > 0 && !isNaN(element('battery-voltage').value) &&
            parseFloat(element('battery-voltage').value) > 0 &&
            element('appliance-load').value.length > 0 && !isNaN(element('appliance-load').value) &&
            parseFloat(element('appliance-load').value) > 0 &&
            element('battery-voltage').value.length > 0 && !isNaN(element('battery-voltage').value)
            && parseFloat(element('battery-voltage').value) > 0 &&
            element('single-cell-volt').value.length > 0 && !isNaN(element('single-cell-volt').value)
            && parseFloat(element('single-cell-volt').value) > 0 &&
            element('capacity-per-cell').value.length > 0 && !isNaN(element('capacity-per-cell').value)
            && parseFloat(element('capacity-per-cell').value) > 0
        ) {
            return '';
        }

    }
    return '<p class="alert alert-danger">Expecting Valid Numbers</p>';

}
const generateResult = (calculation) => {
    if(calculation.type == 'batterypack')
    {
        return `<p></p>
        <u><h4>Battery Pack Configuration</h4></u>
        <p><span class="font-weight-bold">Cells in Series</span>: <kbd>~ ${Math.ceil(calculation.cellSeries)}<kbd></p>
        <p><span class="font-weight-bold">Cells in Parallel</span>: <kbd>~ ${Math.ceil(calculation.cellParallel)}<kbd></p>
        <p><span class="font-weight-bold">Total cells needed</span>: <kbd>~ ${Math.ceil(calculation.cellSeries) * Math.ceil(calculation.cellParallel)}<kbd></p>
        `;
    }
    if(calculation.type == 'batterycapacity')
    {
        return `<span class="font-weight-bold">Battery Capacity needed</span>: <kbd>~ ${Math.ceil(calculation.capacity)} Ah</kbd>`;
    }
    return '';
}

const showResult = (validation) => {
    let output = "";
    if (validation.length > 0) {
        output = validation;
    } else {
        const batteryCapacityCalculation = calculateBatteryCapacity();
        const batteryPackCalculation = getBatteryPackConfiguration();
        if(document.getElementById('calc-type').value == '1') {
            output = generateResult(batteryCapacityCalculation);
        }
        if(document.getElementById('calc-type').value == '2') {
            output = generateResult(batteryCapacityCalculation);
            output += generateResult(batteryPackCalculation);
        }
        output += `<p></p><p></p>
        <p class="font-italic small footnote">** All results are approximate values, ie, decimals are rounded to near highest values to give better configuration</p>`
    }

    document.getElementById("resultblock").classList.remove('d-none');
    document.getElementById('result').innerHTML = `${output}`;

}

export {
    showResult,
    validateForm
};